
body {
    margin: 0;
    padding: 0;
    background: linear-gradient(-45deg, #2d0229, #38012a, #4d034a, #6b0362);
    background-size: 400% 400%;
    animation: gradient 15s ease infinite;
    --cui-body-font-family: "Orbitron", sans-serif;
}

.logo {
    position: absolute;
    top: 10px;
    left: 50%;
    color: #262626;
    padding: 20px 50px;
}


.BigDaddyContainer {
    min-height: 100vh;
    min-width: 100vh;
    margin: 0;
    background: linear-gradient(-45deg, #47013e, #64114f, #94118d, #c708b7);
    background-size: 400% 400%;
    animation: gradient 15s ease infinite;
}

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}

@keyframes flip {

    0%,
    80% {
        transform: rotateY(360deg);
    }
}

.modal {
    opacity: 1;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(77, 77, 77, .7);
    transition: all .4s;
    z-index: 1001;
}

.modal__content {
    border-radius: 4px;
    position: relative;
    width: 500px;
    max-width: 90%;
    background: #fff;
    color: #000;
    padding: 1em 2em;
    max-height: 80vh;
    overflow: auto;
    word-wrap: break-word;
}

.modal__close {
    position: absolute;
    top: 10px;
    right: 10px;
    color: #585858;
    text-decoration: none;
}


.BigDaddyContainer h2 {
    font-size: 2em;
    color: #000000;
    text-shadow: 1px 1px #000;
    margin-top: 20px;
}

.BigDaddy-form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background: linear-gradient(rgba(123, 104, 238, 0.8), rgba(0, 0, 0, 1));
}

.BigDaddy-label {
    color: #FFF;
    font-size: 20px;
    margin-bottom: 10px;
    display: block;
}

.BigDaddy-input {
    padding: 10px;
    margin-bottom: 20px;
    border: none;
    border-radius: 4px;
    width: 400px;
    display: block;
}

.logout-button {
    position: absolute;
    top: 20px;
    right: 20px;
    padding: 10px;
    border: none;
    border-radius: 4px;
    background-color: #4B0082;
    color: #FFF;
    cursor: pointer;
}

.logout-button:hover {
    background-color: #6A5ACD;
}

.bigdaddy-button {
    width: 220px;
    height: 50px;
    border: none;
    outline: none;
    color: #ffffff;
    background: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000);;
    cursor: pointer;
    position: relative;
    z-index: 0;
    border-radius: 10px;
    margin: 20px;
}

.bigdaddy-button:before {
    content: '';
    background: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000);
    position: absolute;
    top: -2px;
    left: -2px;
    background-size: 400%;
    z-index: -1;
    filter: blur(5px);
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    animation: glowing 20s linear infinite;
    opacity: 0;
    transition: opacity .3s ease-in-out;
    border-radius: 10px;
}

.bigdaddy-button:active {
    color: #000
}

.bigdaddy-button:active:after {
    background: transparent;
}

.bigdaddy-button:hover:before {
    opacity: 1;
}

.bigdaddy-button:after {
    z-index: -1;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, rgb(247, 4, 145), rgb(85, 3, 85));
    left: 0;
    top: 0;
    border-radius: 10px;
}

.bigdaddy-button.logout {
    position: absolute;
    top: 10px;
    right: 10px;
}

.bigdaddy-button.buy {
    margin-left: 150px;
}

.bigdaddy-button.menu {
    width: 120px;
    height: 60px;
}

.bigdaddy-button.large {
    width: 250px;
    height: 60px;
}

@keyframes glowing {
    0% {
        background-position: 0 0;
    }

    50% {
        background-position: 400% 0;
    }

    100% {
        background-position: 0 0;
    }
}

.contentContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row; }

.formContainer {
    flex: 1;
    margin-right: 50px;
}

.helpCard {
    width: 100%;
    display: block;
    justify-content: center;
    align-items: center;
}

.cardContent {
    display: flex;
    justify-content: center;
    width: 100%; /* Modifié pour prendre toute la largeur */
    aspect-ratio: 1/1;
    background: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(10px);
    border-radius: 15px;
    border: 5px solid rgba(255, 255, 255, 0.4);
    color: rgba(255, 255, 255, 0.4);
    font-size: 15vw;
    text-align: center;
}

.BigDaddycolumn {
    flex: 1;
    display: flex;
    flex-direction: column;
    bottom: 100px;
}

.BigDaddycolumn.left {
    justify-content: flex-start;
    width: 30%;
    height: 50%;
    background: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(10px);
    border-radius: 15px;
    border: 5px solid rgba(255, 255, 255, 0.4);
    color: black;
    font-size: 30px;
    padding: 10px;
    position: absolute;
    left: 10px;
}

.BigDaddycolumn.right {
    justify-content: flex-start;
    width: 30%;
    height: 50%;
    background: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(10px);
    border-radius: 15px;
    border: 5px solid rgba(255, 255, 255, 0.4);
    color: black;
    font-size: 30px;
    padding: 10px;
    position: absolute;
    right: 10px;
}

.nft-item {
    cursor: pointer;
    padding: 5px;
    margin: 5px 0;
    border-radius: 5px;
    width: 100%;
    font-weight: bold;text-align: center;
    background: rgba(255, 255, 255, 0.2);
}

.nft-item.selected {
    background: linear-gradient(to right, rgb(247, 4, 145), rgb(76, 192, 238));
    color: white;
}

.BigDaddyuserProfile {
    background: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(10px);
    border-radius: 15px;
    border: 5px solid rgba(255, 255, 255, 0.4);
    color: black;
    position: absolute;
    left: 10px;
    top: 10px;
}

.BigDaddy-left-table-footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    left: 10px;
    bottom: 10px;
    position: absolute;
}

.BigDaddy-right-table-footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    right: 10px;
    bottom: 10px;
    position: absolute;
}

.navbar-text-gradient {
    font-size: 42px; /* Ajustez à votre convenance */
    background: linear-gradient(to right, rgb(247, 4, 145), rgb(85, 3, 85));
    font-weight: bold;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.navbar-text-gradient-user-menu {
    font-size: 14px; /* Ajustez à votre convenance */
    background: linear-gradient(to right, rgb(247, 4, 145), rgb(85, 3, 85));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.cardImageStyle {
    height: 150px; /* or whatever height you want */
    object-fit: cover;
    width: 300px;
}

.how-it-works-container {
    padding: 20px;
    font-family: Arial, sans-serif;
    color: #fff;
}

.how-it-works-container h2 {
    text-align: center;
    margin-bottom: 30px;
}

.step {
    background-color: rgba(255, 255, 255, 0.1);
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 5px;
}

.step h3 {
    color: #c708b7;
}

.summary {
    background-color: rgba(255, 255, 255, 0.2);
    padding: 20px;
    border-radius: 5px;
}

.waviy {
    position: relative;
}

.waviy span {
    position: relative;
    display: inline-block;
    font: normal 100px Varela Round, sans-serif;
    color: #d61897;
    text-transform: uppercase;
    animation: flip 2s infinite;
    animation-delay: calc(.2s * var(--i));
}

@keyframes flip {

    0%,
    80% {
        transform: rotateY(360deg);
    }
}

canvas {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.homeText {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 84px;
    background: linear-gradient(-45deg, #47013e, #64114f, #94118d, #c708b7);
    -webkit-background-clip: text;
    color: transparent;
    font-weight: bold;
    text-align: center;
}

.mobile-nav-center {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.bdn-centered-title {
    padding-top: 50px;
    padding-left: 50px;
    padding-right: 50px;
    text-align: center;
    color: purple;
    font-size: 3em;  /* Default size for larger screens */
}

.bdn-transparent-card {
    background: rgba(255, 255, 255, 0.6);  /* Fond transparent */
    border: none;  /* Pas de bordure */
}

.animated-card {
    transition: all 0.3s ease;
}

.animated-card:hover {
    transform: scale(1.05);
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.3);
}

/* Media query for screens smaller than 1200px (Bootstrap's XL breakpoint) */
@media (max-width: 1199px) {
    .bdn-centered-title {
        font-size: 2em;  /* Smaller font size for mobile */
    }
}

.card-container {
    display: flex;
    align-items: stretch;
}
